import Service from './Service';
import Axios from 'axios';

export default class CompanyWorkersService extends Service {
  private static instance: CompanyWorkersService;

  private constructor() {
    super();
  }

  public static getInstance(): CompanyWorkersService {
    if (!CompanyWorkersService.instance) {
      CompanyWorkersService.instance = new CompanyWorkersService();
    }
    return CompanyWorkersService.instance;
  }

  public async uploadWorkers(
    companyId: string,
    csvContent: string
  ): Promise<boolean> {
    try {
      const response = await Axios.post(
        `${this.url}/companies/${companyId}/workers/upload`,
        { csvContent },
        this.config()
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }
}
