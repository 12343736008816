const style = {
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 20px',
        marginLeft: '10px'
    },

    buttonImage: {
        width: '20px',
        height: '20px',
        marginRight: '10px'
    },

    buttonText: {
        marginLeft: '5px',
        fontSize: '14px'
    },

    lottie: {
        width: '20px',
        height: '20px'
    },

    fileInput: {
        display: 'none'
    }
} as const;

export default style;
