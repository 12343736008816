import * as React from 'react';
import { useRef, useState } from 'react';
import UploadCsvButtonViewProps from './UploadCsvButtonViewProps';
import Lottie from 'lottie-react-web';
import TranslationView from '../../../../components/translationView/TranslationView';
import CompanyWorkersService from '../../../../services/CompanyWorkersService';
import AlertHelper from '../../../../helpers/AlertHelper';
import style from './UploadCsvButtonViewStyle';
import PopUpView from '../../components/popUpView/PopUpView';

const companyWorkersService = CompanyWorkersService.getInstance();

const UploadCsvButtonView: React.FC<UploadCsvButtonViewProps> = ({ 
    companyId, 
    isBusy: propIsBusy = false,
    onUploadComplete 
}) => {
    const [isBusy, setIsBusy] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setSelectedFile(file);
        setShowConfirmation(true);
        
        // Clear the input so the same file can be selected again
        event.target.value = '';
    };

    const handleUpload = async () => {
        if (!selectedFile) return;
        setIsBusy(true);

        try {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const csvContent = e.target?.result as string;
                
                try {
                    await companyWorkersService.uploadWorkers(
                        companyId,
                        csvContent
                    );
                    
                    AlertHelper.success('Workers list uploaded successfully');
                    if (onUploadComplete) {
                        onUploadComplete();
                    }
                } catch (error) {
                    AlertHelper.danger('Failed to upload workers list');
                    console.error('Upload error:', error);
                }

                setIsBusy(false);
                setShowConfirmation(false);
                setSelectedFile(null);
            };

            reader.readAsText(selectedFile);
        } catch (error) {
            AlertHelper.danger('Failed to read CSV file');
            console.error('File read error:', error);
            setIsBusy(false);
            setShowConfirmation(false);
            setSelectedFile(null);
        }
    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setSelectedFile(null);
    };

    const isButtonBusy = propIsBusy || isBusy;

    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                accept=".csv"
                onChange={handleFileChange}
                style={style.fileInput}
            />
            <button 
                disabled={isButtonBusy} 
                onClick={handleClick} 
                type="button" 
                className="btn btn-primary" 
                style={style.button}
            >
                {isButtonBusy ? (
                    <Lottie
                        options={{
                            animationData: require('../saveButtonView/assets/loading.json')
                        }}
                        style={style.lottie}
                    />
                ) : (
                    <span>
                        <img src='/assets/icon_upload_2.svg' style={style.buttonImage} />
                        <span className='roboto' style={style.buttonText}>
                            <TranslationView>
                                uploadWorkers
                            </TranslationView>
                        </span>
                    </span>
                )}
            </button>

            <PopUpView
                theme="warning"
                illustration="warning"
                title="uploadWorkersWarning"
                toggled={showConfirmation}
                onToggle={handleCancel}
                onClick={handleUpload}
                onIgnore={handleCancel}
                actionTitle="Upload"
                ignoreTitle="oopsNoGoBack"
            />
        </>
    );
};

export default UploadCsvButtonView;
