import { App } from '../app';
import config from '../config';
import Axios, { AxiosPromise } from 'axios';
import ConnectionService from './ConnectionService';
import RefreshTokenService from './RefreshTokenService';

export default class Service {
  /* ATTRIBUTES */
  static interceptor = -1;
  static BEARER_TOKEN = '';
  static REFRESH_TOKEN = '';

  config = () => {
    return { headers: { Authorization: `bearer ${Service.BEARER_TOKEN}` } };
  };
  url: string = config.azure.resource.url;
  newUrl: string = config.azure.newResource.url;

  /* CONSTRUCTOR */
  constructor() {
    if (Service.interceptor === -1) {
      Service.interceptor = Axios.interceptors.response.use(
        this.handleResponse,
        this.handleError
      );
    }

    this.getUrl = this.getUrl.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.createInterceptor = this.createInterceptor.bind(this);
  }

  getUrl(version: string) {
    if (version === 'new-api') {
      return this.newUrl;
    }
    return this.url.replace('v1', version);
  }

  /* HANDLERS */
  handleResponse(response: any) {
    return response;
  }

  async handleError(error: any, errorCallBack?: any) {
    const thisService = this;

    if (`${error.response.status}` !== `401`) {
      if (errorCallBack) {
        errorCallBack();
      }

      return Promise.reject(error);
    }

    const refreshToken = Service.REFRESH_TOKEN;

    if (refreshToken != '') {
      try {
        const result = await RefreshTokenService.post(refreshToken);

        Service.BEARER_TOKEN = result.access_token;
        Service.REFRESH_TOKEN = result.refresh_token;

        error.config.headers[
          'Authorization'
        ] = `Bearer ${Service.BEARER_TOKEN}`;

        return Axios.request(error.config);
      } catch (e) {
        Service.BEARER_TOKEN = '';
        Service.REFRESH_TOKEN = '';
        alert({
          message: "You've been deconnected after 15'.",
          type: 'warning',
        });
        App.history.push('/login');
        return Promise.reject();
      }
    } else {
      Service.BEARER_TOKEN = '';
      Service.REFRESH_TOKEN = '';
      alert({ message: "You've been deconnected after 15'.", type: 'warning' });
      App.history.push('/login');
      return Promise.reject();
    }
  }

  /* METHODS */
  createInterceptor() {
    Axios.interceptors.response.eject(Service.interceptor);
    Service.interceptor = Axios.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  }

  async execute(promise: AxiosPromise, message?: string) {
    try {
      promise.catch((data) => {
        alert({
          type: 'danger',
          message: message
            ? message
            : data?.response?.data ?? 'Something went wrong.',
        });
      });
      return (await promise).data;
    } catch (exception) {
      console.warn(exception);
    }
  }
}
